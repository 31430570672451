<template>
  <div class="overflow-hidden">
    <HeroV2 :visible.sync="visible">
      <XrayWrapperHome />
    </HeroV2>
    <b-container class="mt-lg-n8 mt-sm-n4 mt-4">
      <b-row>
        <b-col cols="6" class="px-1 px-md-4 px-lg-4">
          <div
            class="
              cohort-card
              text-center
              h-100
              bg-white
              rounded
              py-4 py-md-8
              position-relative
              transition-fast
            "
            :class="hoverForDealers ? 'shadow-lg top-n2' : 'shadow top-0'"
            @mouseover="hoverForDealers = true"
            @mouseleave="hoverForDealers = false"
          >
            <div class="px-2 px-sm-4 px-lg-2 px-xl-4">
              <img
                src="@/assets/img/for-dealers.png"
                class="w-100"
                alt="For Dealers"
              />

              <b-link
                @focus="hoverForDealers = true"
                @blur="hoverForDealers = false"
                to="/dealers"
                class="
                  text-uppercase
                  leading-none
                  text-blue-500
                  font-realist-wide
                  text-sm text-sm-lg text-lg-3xl
                  font-weight-black
                  stretched-link
                  text-decoration-none
                  transition-fast
                "
                ><span class="d-none d-md-inline">For </span>Dealers</b-link
              >

              <p
                class="
                  text-lg-xl text-sm-xs text-2xs text-blue-800
                  font-weight-medium
                "
              >
                Unbiased third party inspections for used inventory
                <br class="d-none d-lg-inline" />
                on retail and wholesale units.
              </p>
              <b-button
                variant="green-500"
                class="
                  mt-auto
                  font-weight-bold font-realist-normal
                  text-2xs text-md-sm text-lg-xl
                "
                to="/dealers"
                >Learn more</b-button
              >
            </div>
          </div>
        </b-col>
        <b-col cols="6" class="px-1 px-md-4 px-lg-4">
          <div
            class="
              cohort-card
              text-center
              h-100
              bg-white
              rounded
              py-4 py-md-8
              position-relative
              transition-fast
            "
            :class="hoverForCommercial ? 'shadow-lg top-n2' : 'shadow top-0'"
            @mouseover="hoverForCommercial = true"
            @mouseleave="hoverForCommercial = false"
          >
            <div class="px-2 px-sm-4 px-lg-2 px-xl-4">
              <img
                src="@/assets/img/for-commercial.png"
                class="w-100"
                alt="For Commercial"
              />

              <b-link
                @focus="hoverForCommercial = true"
                @blur="hoverForCommercial = false"
                to="/commercial"
                class="
                  text-uppercase
                  leading-none
                  text-blue-500
                  font-realist-wide
                  text-sm text-sm-lg text-lg-3xl
                  font-weight-black
                  stretched-link
                  text-decoration-none
                  transition-fast
                "
                ><span class="d-none d-md-inline">For </span> Commercial</b-link
              >

              <p
                class="
                  text-lg-xl text-sm-xs text-2xs text-blue-800
                  font-weight-medium
                "
              >
                Inspection and inventory solutions for
                <br class="d-none d-lg-inline" />
                off lease and fleet vehicles.
              </p>
              <b-button
                variant="green-500"
                class="
                  mt-auto
                  font-weight-bold font-realist-normal
                  text-2xs text-md-sm text-lg-xl
                "
                to="/commercial"
                >Learn more</b-button
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <main>
      <div class="mt-lg-48 mt-24">
        <b-container fluid="sm" class="position-relative">
          <b-row>
            <b-col lg="6" class="z-1">
              <div>
                <div class="mb-8 mb-md-12">
                  <h2
                    class="
                      font-weight-black
                      tracking-tight
                      text-3xl text-md-6xl text-xl-7xl
                      font-realist
                      leading-none
                      text-blue-500
                    "
                  >
                    Unparalleled Transparency
                  </h2>
                </div>
                <div>
                  <p class="text-xl text-md-2xl">
                    True360's nationwide vehicle condition inspectors perform
                    <em class="text-orange-500 font-weight-bold"
                      >the most in-depth, third-party inspection</em
                    >
                    in the industry. We have the tools necessary to get you the
                    most from your used inventory.
                  </p>
                </div>
              </div>
            </b-col>
            <b-col lg="6">
              <div>
                <img
                  class="w-100 w-lg-auto"
                  :src="require('@/assets/img/transparency.png')"
                />
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="mt-24 mt-lg-48">
        <b-container>
          <b-row>
            <b-col lg="6">
              <div class="mb-0 mb-md-12 mb-lg-0">
                <ReachMap />
              </div>
            </b-col>
            <b-col lg="6" class="d-flex align-items-center">
              <div>
                <div class="mb-8">
                  <h2
                    class="
                      font-weight-black
                      tracking-tight
                      text-3xl text-md-6xl text-xl-7xl
                      font-realist
                      leading-none
                      text-blue-500
                    "
                  >
                    Inspectors Nationwide
                  </h2>
                </div>
                <p class="text-xl text-md-2xl mb-0">
                  True360 has
                  <em class="text-orange-500 font-weight-bold"
                    >the nation's largest field inspection team.</em
                  >
                  Wherever your vehicles are, you can trust that we'll get to
                  them faster than anyone else.
                </p>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="mt-32 mt-lg-48 pt-8 pt-lg-32 skew-top-ltr bg-gray-100 ">
        <b-container>
          <div class="text-left">
            <div class="mb-8">
              <h2
                class="
                      font-weight-black
                      tracking-tight
                      text-3xl text-md-6xl text-xl-7xl
                      font-realist
                      leading-none
                      text-blue-500
                      mb-0
                    "
              >
                Customizable Solutions
              </h2>
            </div>
            <p class="text-xl text-md-2xl mb-8">
              True360 services can be scaled to fit the needs of your inventory.
              The unique benefits of our reports are suited for one, some, or
              all of your vehicles.
            </p>
          </div>
          <div class="embed-responsive embed-responsive-16by9">
            <img
              class="embed-responsive-item"
              src="@/assets/img/slide-car-02.png"
              alt=""
            />
          </div>
        </b-container>

        <b-container class="mt-24 mt-lg-48">
          <b-row>
            <b-col xl="6" class="d-xl-flex align-items-center">
              <div class="mb-12">
                <div class="mb-8">
                  <h2
                    class="
  font-weight-black
  tracking-tight
  text-3xl text-md-6xl text-xl-7xl
  font-realist
  leading-none
  text-blue-500
  "
                  >
                    Protect Your Brand
                  </h2>
                </div>
                <p class="text-xl text-md-2xl mb-0">
                  True360 Reports protect your brand and reduce liability,
                  allowing you to sell in complete confidence.
                </p>
              </div>
            </b-col>
            <b-col
              lg="8"
              offset-lg="2"
              offset-xl="0"
              xl="6"
              class="d-flex align-items-center position-relative"
            >
              <div class="right-n48 position-xl-absolute">
                <img
                  class="w-100 shadow-lg"
                  src="@/assets/img/certificate.jpg"
                />
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="mt-md-48 mt-24 mt-lg-56 pb-8 pb-lg-48">
        <b-container class="mb-8 mb-md-12">
          <b-row>
            <b-col>
              <div class="text-left text-lg-center">
                <div class="mb-8">
                  <h2
                    class="
                      font-weight-black
                      tracking-tight
                      text-3xl text-md-6xl text-xl-7xl
                      font-realist
                      leading-none
                      text-blue-500
                    "
                  >
                    Industry Leaders Trust our Transparency
                  </h2>
                </div>
                <p class="text-xl text-md-2xl">
                  Serving individual dealers large and small and the nation's
                  largest consignors.
                </p>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <div>
          <SwiperCarousel>
            <SwiperSlide v-for="(t, idx) in testimonials" :key="idx">
              <TestimonialCard
                :name="t.name"
                :title="t.title"
                :headshot="t.headshot"
                :companyName="t.companyName"
                :companyLogo="t.companyLogo"
                :quote="t.quote"
              />
            </SwiperSlide>
          </SwiperCarousel>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import TestimonialCard from "@/components/testimonial/TestimonialCard";
import XrayWrapperHome from "@/components/XrayWrapperHome";
import HeroV2 from "@/components/global/HeroV2";
import ReachMap from "@/components/ReachMap";
import SwiperCarousel from "@/components/swiper/SwiperCarousel";
import { SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "Home",
  components: {
    TestimonialCard,
    HeroV2,
    ReachMap,
    XrayWrapperHome,
    SwiperCarousel,
    SwiperSlide
  },
  watch: {
    visible() {
      this.$emit("update:heroIsVisible", this.visible);
    }
  },
  data() {
    return {
      hoverForDealers: false,
      hoverForCommercial: false,
      visible: true,
      testimonials: [
        {
          name: "Tony Guerrera",
          title: "Pre-Owned Sales Manager",
          companyName: "Vista BMW",
          quote:
            "True360 reports add a layer of transparency to each sale. Each report is like buying peace of mind for us and the customer.",
          companyLogo: "@/assets/img/testimonials/logos/vista.png"
        },
        {
          name: "Fred Mueller",
          title: "Pre-Owned Manager",
          companyName: "Dean Honda",
          quote:
            "With the True360 we were able to defend the car and provide a 3rd party, unbiased report card on the vehicle. We were able to maintain value, especially during the negotiation process.",
          companyLogo: "@/assets/img/testimonials/logos/vista.png"
        },
        {
          name: "Marty Francis",
          title: "Pre-Owned Directory",
          companyName: "Lilliston Auto Group",
          quote:
            "I would encourage every dealership to use True360. This service is an incredible sales tool that will close deals and help you retain profit.",
          companyLogo: "@/assets/img/testimonials/logos/vista.png"
        }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.cohort-card {
  position: relative;
  z-index: 2;
  img {
    max-width: 85%;
    margin-top: -48px;
    margin-bottom: 12px;
    @media (min-width: 767px) {
      margin-top: -48px;
      margin-bottom: 18px;
    }
    @media (min-width: 992px) {
      margin-top: -50px;
      margin-bottom: 24px;
    }

    & + a {
      display: block;
      margin-bottom: 0.5em;
      @media (max-width: 413px) {
        margin-top: -0.25em;
      }
    }
  }
}
.swiper-slide {
  opacity: 0.6;
  transform: scale(0.9);
  transition: all 0.2s ease;

  & > div {
    box-shadow: 0 0.375rem 0.5rem rgba(0, 0, 0, 0.0625),
      0 0.625rem 0.5rem rgba(0, 0, 0, 0.025) !important;
  }

  &.swiper-slide-active {
    opacity: 1;
    transform: scale(1);

    & > div {
      box-shadow: 0 1rem 1.25rem rgba(0, 0, 0, 0.0625),
        0 0.875rem 0.75rem rgba(0, 0, 0, 0.025) !important;
    }
  }
}
</style>
