<template>
  <div>
    <swiper class="pb-8" ref="mySwiper" :options="swiperOptions">
      <slot />
      <div class="swiper-pagination bottom-0" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper } from "vue-awesome-swiper";
import "swiper/swiper.min.css";

export default {
  name: "SwiperCarousel",
  components: {
    Swiper
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        centeredSlides: true,
        slidesPerView: 1.25,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        breakpoints: {
          768: {
            slidesPerView: 1.5
          },
          1200: {
            slidesPerView: 2
          }
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  }
};
</script>

<style lang="scss" scoped>
.swiper-slide {
  opacity: 0.4;
  transform: scale(0.8);

  &.swiper-slide-active {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
